<template>
  <el-main>
    <div class="topBox">
      <el-button type="primary" size="mini" @click="startDialog">+ 添加客服</el-button>
      <div class="tips">
        绑定客服账号，可以登录
        <span @click="webWxUrl">网页端客服</span>
        或移动端
        <el-popover placement="top-start" title="请使用微信扫码" width="300" trigger="hover">
          <img style="width: 270px" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/miniCuserom.png" alt="" />
          <el-button type="text" slot="reference">小程序客服</el-button>
        </el-popover>
        进行客服沟通，最多支持绑定100个
      </div>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="客服微信号：">
        <el-input size="small" placeholder="请输入微信号" v-model="ruleForm.kf_wx"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getDataList(1)">搜索</el-button>
        <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="客服信息" align="center">
        <template v-slot="{ row }">
          <UserInfo style="" :name="row.kf_nick" :avatar="row.kf_headimgurl"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column prop="kf_wx" label="客服微信号" align="center"></el-table-column>
      <el-table-column label="绑定时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="deleteCustom(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <!-- 添加客服弹框 -->
    <el-dialog title="绑定客服人员" :visible.sync="addDialogFlag" width="400px">
      <div class="addForm">
        <div class="title">客服微信号：</div>
        <el-input placeholder="请输入微信号" v-model="customWxNum"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureAddBtn">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate';
import UserInfo from '@/components/userInfo';
export default {
  components: {
    Paging,
    UserInfo,
  },
  data() {
    return {
      getDateformat,
      page: 1,
      rows: 10,
      total: 0,
      ruleForm: {
        kf_wx: '',
      },
      dataList: [],
      addDialogFlag: false,
      customWxNum: '',
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    cancelSearch() {
      this.page = 1;
      this.ruleForm.kf_wx = '';
      this.getDataList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getDataList();
    },
    getDataList(style) {
      if (style) this.page = 1;
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.ruleForm.kf_wx) obj.kf_wx = this.ruleForm.kf_wx;
      this.$axios.post(this.$api.set.wechatCustomer.list, obj).then(res => {
        if (res.code === 0) {
          this.dataList = res.result.list;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    startDialog() {
      this.customWxNum = '';
      this.addDialogFlag = true;
    },
    sureAddBtn() {
      if (this.customWxNum === '') {
        this.$message.warning('请输入微信号');
        return;
      }
      this.$axios
        .post(this.$api.set.wechatCustomer.add, {
          kf_wx: this.customWxNum,
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('添加成功');
            this.customWxNum = '';
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    deleteCustom(data) {
      this.$confirm('确认删除此客服？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .post(this.$api.set.wechatCustomer.del, {
              id: data.id,
            })
            .then(res => {
              if (res.code == 0) {
                this.$message({
                  message: '删除成功',
                  type: 'success',
                });
                this.getDataList();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(err => {});
    },
    webWxUrl() {
      window.open('https://mpkf.weixin.qq.com/');
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .topBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .tips {
      font-size: 12px;
      color: #ccc;
      margin-left: 20px;
      span {
        color: #409eff;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .addForm {
    display: flex;
    .title {
      width: 120px;
      text-align: right;
      align-items: center;
      line-height: 40px;
    }
  }
}
</style>
